<script lang="ts" setup>
const { url } = defineProps<{ url: string }>();

const wrapper = ref();

let embedCodeFetchURL = `https://www.youtube.com/oembed?format=json&url=${encodeURIComponent(
  url,
)}`;
if (url.includes("vimeo")) {
  embedCodeFetchURL = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
    url,
  )}`;
}

const { data } = await useFetch<{ html: string }>(embedCodeFetchURL, {
  onResponseError: console.log,
});

const embedHTML = computed(() => {
  if (!data) return null;

  return data.value?.html.replace("width=", "").replace("height=", "");
});

onMounted(() => {
  const iframe = wrapper.value.getElementsByTagName("iframe")[0];
  if (iframe) {
    iframe.style.width = "100%";
    iframe.style.aspectRatio = "16/9";
  }
});
</script>

<template>
  <div v-if="embedHTML" ref="wrapper" v-html="embedHTML" />
</template>
