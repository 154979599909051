<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  buttonWrapperClass: {
    type: String,
    default: "lg:grid-cols-2",
  },
  fontStyle: {
    type: String,
    default: "",
  },
  isFullColumn: {
    type: Boolean,
    default: false,
  },
  addMarginRight: {
    type: Boolean,
    default: false,
  },
  addMarginLeft: {
    type: Boolean,
    default: false,
  },
});

const justifyClass = computed(() => {
  switch (props.blok.alignment) {
    case "top": {
      return "justify-start";
    }
    case "bottom": {
      return "justify-end";
    }
    default: {
      return "justify-center";
    }
  }
});

const hasContent = computed(() => {
  const htmlContent = renderRichText(props.blok.text);
  return !!(
    props.blok.heading ||
    props.blok.subheading ||
    htmlContent ||
    props.blok.buttons.length > 0
  );
});
</script>

<template>
  <div
    v-editable="blok"
    class="flex flex-col gap-5 md:gap-8 px-0 md:px-0"
    :class="justifyClass"
    :style="{ backgroundColor: blok?.backgroundColor ?? 'inherit' }"
  >
    <VideoEmbed v-if="blok.video" :url="blok.video" />
    <div v-for="image in blok.image" v-else :key="image._uid" class="relative">
      <Image :blok="image" class="flex justify-end sm:m-0 w-full">
        <div v-if="image.association" class="p-2.5">
          <FavoriteButton :product-uuid="image.association" />
        </div>
      </Image>
    </div>

    <div
      v-if="hasContent"
      class="flex flex-col md:gap-10 gap-5 px-2.5 md:px-0"
      :class="{
        'xl:me-10 lg:me-5 md:me-2.5': addMarginRight,
        'xl:ms-10 lg:ms-5 md:ms-2.5': addMarginLeft,
      }"
    >
      <div
        v-if="blok.subheading || blok.heading"
        class="flex flex-col gap-[5px]"
        :class="{
          'max-w-[450px]': !isFullColumn,
          'max-w-[850px]': isFullColumn,
        }"
      >
        <p v-if="blok.subheading" class="font-helvetica-bold text-base">
          {{ blok.subheading }}
        </p>
        <h2
          class="font-helvetica-light text-[25px] lg:text-[33px] leading-[120%]"
          :style="{ color: blok.headingColor || 'inherit' }"
        >
          {{ blok.heading }}
        </h2>
      </div>
      <div class="flex flex-col gap-6">
        <RichText
          v-editable="blok"
          :content="blok.text"
          :class="{
            [fontStyle]: true,
            'max-w-[450px]': !isFullColumn,
            'max-w-[850px]': isFullColumn,
          }"
        />
        <div
          v-if="blok.buttons?.length > 0"
          class="grid grid-cols-1"
          :class="buttonWrapperClass"
        >
          <div class="flex flex-col items-start">
            <Button
              v-for="button in blok.buttons"
              :key="button._uid"
              :blok="button"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
